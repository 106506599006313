import React from "react";

const MealTimetablePrivacyPolicy = () => {
    const contactUsEmail = process.env.REACT_APP_CONTACT_US_EMAIL;
    return (
        <div className="privacy-policy-main-container">
            <h3>Privacy Policy</h3>
            <h1 className="privacy-policy-main-title">BuzzLine</h1>
            <p><b>Last updated 23 January 2025</b></p>
            <p>This privacy notice for Noble Notch Ltd ('we', 'us', or 'our'), describes how and why we might collect,
                store, use, and/or share ('process') your information when you use our services ('Services'), such as
                when you:</p>
            <ul>
                <li>Download and use our mobile application (BuzzLine), or any other application of ours that
                    links to this privacy notice
                </li>
                <li>Engage with us in other related ways, including any sales, marketing, or events</li>
            </ul>

            <p><b>Questions or concerns?</b> Reading this privacy notice will help you understand your privacy rights
                and choices. If you do not agree with our policies and practices, please do not use our Services. If you
                still have any questions or concerns, please contact us at <a
                    href={"mailto:" + contactUsEmail}>{contactUsEmail}</a></p>


            <h4>1. What Information Do We Collect?</h4>
            <p><b>Application Data.</b> If you use our application(s), we also may collect the following information if
                you choose to provide us with access or permission:</p>
            <ul>
                <li><b>Mobile Device Data. </b>We automatically collect device information (such as your mobile device
                    ID, model, and manufacturer), operating system, version information and system configuration
                    information, device and application identification numbers, browser type and version, hardware model
                    Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy
                    server). If you are using our application(s), we may also collect information about the phone
                    network associated with your mobile device, your mobile device’s operating system or platform, the
                    type of mobile device you use, your mobile device’s unique device ID, and information about the
                    features of our application(s) you accessed.
                </li>
                <li><b>Push Notifications.</b> We may request to send you push notifications regarding your account or
                    certain features of the application(s). If you wish to opt out from receiving these types of
                    communications, you may turn them off in your device's settings.
                </li>
            </ul>
            <p>This information is primarily needed to maintain the security and operation of our application(s), for
                troubleshooting, and for our internal analytics and reporting purposes.</p>
            <h5>Information automatically collected</h5>
            <p>We automatically collect certain information when you visit, use, or navigate the Services. This
                information does not reveal your specific identity (like your name or contact information) but may
                include device and usage information, such as your IP address, browser and device characteristics,
                operating system, language preferences, referring URLs, device name, country, location, information
                about how and when you use our Services, and other technical information. This information is primarily
                needed to maintain the security and operation of our Services, and for our internal analytics and
                reporting purposes.</p>
            <p>The information we collect includes:</p>
            <ul>
                <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and performance
                    information our servers automatically collect when you access or use our Services and which we
                    record in log files. Depending on how you interact with us, this log data may include your IP
                    address, device information, browser type, and settings and information about your activity in the
                    Services (such as the date/time stamps associated with your usage, pages and files viewed, searches,
                    and other actions you take such as which features you use), device event information (such as system
                    activity, error reports (sometimes called 'crash dumps'), and hardware settings).
                </li>
                <li>Device Data. We collect device data such as information about your computer, phone, tablet, or other
                    device you use to access the Services. Depending on the device used, this device data may include
                    information such as your IP address (or proxy server), device and application identification
                    numbers, location, browser type, hardware model, Internet service provider and/or mobile carrier,
                    operating system, and system configuration information.
                </li>
            </ul>


            <h4>2. How Do We Process Your Information?</h4>
            <p><b>In Short:</b> We process your information to provide, improve, and administer our Services,
                communicate with you, for security and fraud prevention, and to comply with law. We may also process
                your information for other purposes with your consent.</p>


            <h4>3. Controls for Do-Not-Track Features</h4>
            <p>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                ('DNT') feature or setting you can activate to signal your privacy preference not to have data about
                your online browsing activities monitored and collected. At this stage no uniform technology standard
                for recognising and implementing DNT signals has been finalised. As such, we do not currently respond to
                DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked
                online. If a standard for online tracking is adopted that we must follow in the future, we will inform
                you about that practice in a revised version of this privacy notice.</p>


            <h4>4. Do We Make Updates to This Notice?</h4>
            <p><b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with relevant laws.
            </p>
            <p>We may update this privacy notice from time to time. The updated version will be indicated by an
                updated
                'Revised' date and the updated version will be effective as soon as it is accessible. If we make
                material changes to this privacy notice, we may notify you either by prominently posting a notice of
                such changes or by directly sending you a notification. We encourage you to review this privacy
                notice
                frequently to be informed of how we are protecting your information.</p>


            <h4>5. How Can You Contact Us About This Notice?</h4>
            <p>If you have questions or comments about this notice, you may email us at <a
                href={"mailto:" + contactUsEmail}>{contactUsEmail}</a></p>
        </div>
    );
}

export default MealTimetablePrivacyPolicy;